import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import AppsPanel from "../components/apps-panel"

const BlockChainPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Blockchain Smart Contract, NFT Software Developers" />
        <Hero title="On-Demand Blockchain Smart Contract, NFT Software Developers" description="We can help you take your cloud applications to the next level." image="pages/blockchain-hero.jpg" />
        <AboutPanel title="I can help you develop your next NFT or smart contract." description="Hire high-performing, on-demand block-chain developers" image="pages/blockchain-developer.jpg"  />
       <AppsPanel description="On-demand Cloud Application Engineers from elastic team will help you quickly scale up your business. With our team of world-class experts in AWS and GCP, we can help you with everything from scoping and design to development, UAT, and deployment. Let us manage your cloud journey so you can focus on what's important - running your business." />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default BlockChainPage
